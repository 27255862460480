import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import Link from 'react-router/lib/Link';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import * as styles from './OrderDetailComponent.css';
import Reorder from '../Reorder/Reorder';
import Icon from '../../core/Icon/Icon';
import Image from '../../core/Image/Image';
import { convertBGNToEUR, formatHungaryTousends, getImageByFormat, trimString } from '../../../common/Utils/Utils';
import { ORDERS_L10N, URL_PATHS, PAGE_ID, GENERIC_L10N, CART_PRICING_TYPES, DATA_LAYER_LISTS } from '../../../common/Constants';
import { viewItemListEventGA4, productClickEventGA4 } from '../../../common/GoogleTagManager/GoogleTagManager';
import { productImpressionsOrderHistoryDataLayerFillterGA4, productClickOrderDetailDataLayerFilterGA4 } from '../../../service/DataLayerFilter';
import { getEnabledEURConversion } from '../../../common/UserManager/UserManager';
const cx = classNames.bind(styles);

export const OrderDetailComponent = props => {
    const { orderDetailData, localization, commonLocalization = {} } = props;
    const { cchPricingData, orderData, isReOrderable } = orderDetailData;
    const isPricingAvailable = !!_get(orderData, 'subTotal');
    const status = _get(orderData, 'statusDisplay', '');
    const enabledEurConversion = getEnabledEURConversion();

    const breakdownOpen = () => {
        const modalId = document.getElementById('pricebreakdown');
        /* istanbul ignore next */
        if (modalId) {
            modalId.classList.toggle('OrderDetailComponent-pricebreakdownOpen');
        }
    };

    const onProductClick = (item) => {
        try {
            const { product, totalPrice, quantity } = _cloneDeep(item);
            productClickEventGA4(productClickOrderDetailDataLayerFilterGA4(product, totalPrice, quantity), DATA_LAYER_LISTS.REORDER_LIST);
        } catch (ex) {
            console.error(ex);
        }
    }

    useEffect(() => {
        if(orderData) {
            try {
                viewItemListEventGA4(productImpressionsOrderHistoryDataLayerFillterGA4(_cloneDeep(orderData.entries), DATA_LAYER_LISTS.REORDER_LIST));
            } catch (ex) {
                console.error(ex);
            }
        }
    }, [orderDetailData]);

    return (
        <div automation-id='at-order-details-page' className={cx('orderDetailsContainer')}>
            {orderData && localization && commonLocalization ? (
                <div className={cx('orderHeader')}>
                    <div className={cx('orderRecord', 'orderBtmbdr')}>
                        <div className={cx('col3', 'sm12', 'md12', 'pad20', 'morderno', 'hiddenSm')}>
                            <div className={cx('orderno-left', 'md6')}>
                                <h5 automation-id='at-order-number-text' className={cx('tableTitle')}>
                                    {localization[ORDERS_L10N.ORDER_NUMER]}
                                </h5>
                                <span automation-id='at-order-number-link' className={cx('orderId', 'textBrand')}>
                                    {orderData.code}
                                </span>
                            </div>
                            {/* <button type='button' className={cx('reOrderBtn')}> Reorder</button> */}
                            <div className={cx('orderno-right', 'md3')}>
                                {isReOrderable && (
                                    <Reorder
                                        orderId={orderData.code}
                                        customReorderStyle={cx('reOrderBtn')}
                                        buttonValue={localization[ORDERS_L10N.REORDER_BTN]}
                                        automationId='at-reorder-button'
                                        modalLocalization={{
                                            cancelBtn: localization[ORDERS_L10N.REORDER_POPUP_CANCEL_BUTTON],
                                            checkoutBtn: localization[ORDERS_L10N.REORDER_POPUP_CHECKOUT_BUTTON],
                                            header: localization[ORDERS_L10N.REORDER_POPUP_HEADER_TEXT],
                                            subtext: localization[ORDERS_L10N.REORDER_POPUP_SUBTEXT],
                                            warning: localization[ORDERS_L10N.REORDER_POPUP_SUBTEXT_WARNING],
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={cx('col3', 'sm12', 'md4', 'pad20', 'detailsItem')}>
                            <div className={cx('Iteminnerbrd')}>
                                <h5 automation-id='at-placed-by-text' className={cx('tableTitle', 'sm5')}>
                                    {localization[ORDERS_L10N.PLACED_BY]}
                                </h5>
                                <div className={cx('sm7')}>
                                    <span className={cx('shipTo')}>{orderData.user && orderData.user.name}</span>
                                </div>
                            </div>
                        </div>

                        <div className={cx('col3', 'sm12', 'md4', 'pad20', 'detailsItem')}>
                            <div className={cx('Iteminnerbrd')}>
                                <h5 automation-id='at-ship-to-text' className={cx('tableTitle', 'sm5')}>
                                    {localization[ORDERS_L10N.SHIP_TO]}
                                </h5>
                                {orderData.deliveryAddress ? (
                                    <div className={cx('sm7')}>
                                        <span
                                            className={cx('shipTo')}
                                        >{`${orderData.deliveryAddress.firstName} ${orderData.deliveryAddress.lastName}`}</span>
                                        <span className={cx('shipTo')}>{orderData.deliveryAddress.line1}</span>
                                        <span className={cx('shipTo')}>{orderData.deliveryAddress.line2}</span>
                                        <span className={cx('shipTo')}>
                                            {`${orderData.deliveryAddress.postalCode} ${orderData.deliveryAddress.town}`}
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        <div className={cx('col3', 'sm12', 'md4', 'pad20', 'detailsItem')}>
                            <div className={cx('Iteminnerbrd')}>
                                <h5 automation-id='at-billing-address-text' className={cx('tableTitle', 'sm5')}>
                                    {localization[ORDERS_L10N.BILLING_ADDRESS]}
                                </h5>
                                {orderData.billingAddress ? (
                                    <div className={cx('sm7')}>
                                        <span
                                            className={cx('shipTo')}
                                        >{`${orderData.billingAddress.firstName} ${orderData.billingAddress.lastName}`}</span>
                                        <span className={cx('shipTo')}>{orderData.billingAddress.line1}</span>
                                        <span className={cx('shipTo')}>{orderData.billingAddress.line2}</span>
                                        <span className={cx('shipTo')}>
                                            {`${orderData.billingAddress.postalCode} ${orderData.billingAddress.town}`}
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={cx('orderRecord', 'orderBtmbdr')}>
                        <div className={cx('col3', 'sm12', 'mBtn', 'hiddemMd', 'hiddenSm')}> </div>
                        <div className={cx('col9', 'sm12', 'md12', 'rightnopad')}>
                            <div className={cx('orderBtmbdr')}>
                                <div className={cx('rows')}>
                                    <div className={cx('col4', 'sm12', 'trackItem')}>
                                        <h5 automation-id='at-order-status-text' className={cx('tableTitle', 'sm6')}>
                                            {localization[ORDERS_L10N.ORDER_STATUS]}
                                        </h5>
                                        <div className={cx('sm6')}>
                                            <span className={cx('secondaryTxt')}>{status}</span>
                                        </div>
                                    </div>
                                    <div className={cx('col4', 'sm12', 'trackItem')}>
                                        <h5 automation-id='at-date-placed-text' className={cx('tableTitle', 'sm6')}>
                                            {localization[ORDERS_L10N.DATE_PLACED]}
                                        </h5>
                                        <div className={cx('sm6')}>
                                            <span className={cx('secondaryTxt')}>{orderData.formattedCreatedDate}</span>
                                        </div>
                                    </div>
                                    <div className={cx('col4', 'sm12', 'trackItem')}>
                                        <h5 automation-id='at-delivery-date-text' className={cx('tableTitle', 'sm6')}>
                                            {localization[ORDERS_L10N.DELIVERY_DATE]}
                                        </h5>
                                        <div className={cx('sm6')}>
                                            <span className={cx('secondaryTxt')}>
                                                {orderData.formattedDeliveryDate}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={cx('col4', 'sm12', 'trackItem')}>
                                        <h5 automation-id='at-shipping-status-text' className={cx('tableTitle', 'sm6')}>
                                            {localization[ORDERS_L10N.SHIPPING_STATUS]}
                                        </h5>
                                        <div className={cx('sm6')}>
                                            <span className={cx('secondaryTxt')}>
                                                {orderData.deliveryStatusDisplay}
                                            </span>
                                        </div>
                                    </div>

                                    <div className={cx('col4', 'sm12', 'trackItem')}>
                                        <h5 automation-id='at-billing-status-text' className={cx('tableTitle', 'sm5')}>
                                            {localization[ORDERS_L10N.BILLING_STATUS]}
                                        </h5>
                                        <div className={cx('sm7')}>
                                            <span className={cx('secondaryTxt')}>{orderData.billingStatusDisplay}</span>
                                        </div>
                                    </div>

                                    <div className={cx('col4', 'sm12', 'trackItem')}>
                                        <h5
                                            automation-id='at-successor-documents-text'
                                            className={cx('tableTitle', 'sm6')}
                                        >
                                            {localization[ORDERS_L10N.SUCCESSOR_DOCUMENTS]}
                                        </h5>
                                        {orderData.successorDocuments && orderData.successorDocuments.length > 0 ? (
                                            <div className={cx('sm6')}>
                                                <span
                                                    automation-id='at-successor-documents-text'
                                                    className={cx('secondaryTxt')}
                                                >
                                                    {orderData.successorDocuments[0].docNumber}
                                                </span>
                                            </div>
                                        ) : (
                                            '--'
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={cx('rows', 'rowtop')}>
                                <div className={cx('col4', 'sm12', 'mrows')}>
                                    <h5 automation-id='at-payment-terms-text' className={cx('tableTitle', 'sm6')}>
                                        {localization[ORDERS_L10N.PAYMENT_TERMS]}
                                    </h5>
                                    <div className={cx('sm6')}>
                                        <span className={cx('secondaryTxt')}>{orderData.paymentTerms}</span>
                                    </div>
                                </div>
                                <div className={cx('col4', 'sm12', 'mrows')}>
                                    <h5
                                        automation-id='at-no-of-pallets-required-text'
                                        className={cx('tableTitle', 'sm6')}
                                    >
                                        {localization[ORDERS_L10N.NO_OF_PALLETS_REQUIRED]}
                                    </h5>
                                    <div className={cx('sm6')}>
                                        <span className={cx('secondaryTxt')}>{orderData.pallets}</span>
                                    </div>
                                </div>
                                <div className={cx('col4', 'sm12', 'mrows')}>
                                    <h5 automation-id='at-total-weight-text' className={cx('tableTitle', 'sm6')}>
                                        {localization[ORDERS_L10N.TOTAL_WEIGHT]}
                                    </h5>
                                    <div className={cx('sm6')}>
                                        <span className={cx('secondaryTxt')}>
                                            {orderData.weight} {commonLocalization[GENERIC_L10N.WEIGHT_SYMBOL]}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  Item Order List */}

                    <h4 className={cx('detailsitemsCount')}>
                        {commonLocalization[GENERIC_L10N.ITEMS]}
                        <span className={cx('CountTxt')}>
                            {localization[ORDERS_L10N.TOTAL_ITEMS]} <span>({orderData.totalItems})</span>
                        </span>
                    </h4>

                    <div
                        automation-id='at-item-details-table'
                        className={cx(
                            'detailsleft',
                            isPricingAvailable ? 'col9' : 'col12',
                            isPricingAvailable ? 'md8' : 'md12',
                            'sm12'
                        )}
                    >
                        <ul className={cx('detailsleftList')}>
                            <li className={cx('detailstheader', 'hiddenSm')}>
                                <div className={cx('col2', 'md2')} />
                                <div
                                    automation-id='at-product-items'
                                    className={cx(
                                        isPricingAvailable ? 'col5' : 'col8',
                                        isPricingAvailable ? 'md5' : 'md8'
                                    )}
                                >
                                    {localization[ORDERS_L10N.ITEM_TEXT]}
                                </div>
                                <div
                                    automation-id='at-product-quantity'
                                    className={cx(
                                        isPricingAvailable ? 'col2' : 'col2',
                                        isPricingAvailable ? 'md2' : 'md2'
                                    )}
                                >
                                    {localization[ORDERS_L10N.QUANTITY]}
                                </div>
                                {isPricingAvailable && (
                                    <div automation-id='at-price-text' className={cx('col2', 'md2')}>
                                        {localization[ORDERS_L10N.PRICE_TEXT]}
                                    </div>
                                )}
                            </li>
                            {orderData &&
                                (orderData.entries || {}).map(item => {
                                    const { product, totalPrice = {}, freeItem } = item;
                                    const itemPrice = formatHungaryTousends(`${totalPrice.formattedValue}`);
                                    const { images } = product || {};
                                    const image = getImageByFormat(images);
                                    return (
                                        <li className={cx('detailslistItem')} key={item.product.codeShort}>
                                            <div className={cx('col2', 'md2', 'sm4')}>
                                                <div className={cx('prodImage')}>
                                                    <Link onClick={() => onProductClick(item)} to={`${URL_PATHS.PDP}/${item.product.code}`}>
                                                        <Image
                                                            alt={image.altText}
                                                            automation-id='at-product-image'
                                                            src={image.url}
                                                            isInternal={image.defaultImage}
                                                        />
                                                    </Link>
                                                </div>
                                            </div>

                                            <div
                                                className={cx(
                                                    isPricingAvailable ? 'col5' : 'col8',
                                                    isPricingAvailable ? 'md5' : 'md8',
                                                    'sm8'
                                                )}
                                            >
                                                <h2 automation-id='at-product-name'>
                                                    {' '}
                                                    <Link onClick={() => onProductClick(item)} to={`${URL_PATHS.PDP}/${item.product.code}`}>
                                                        {freeItem && (
                                                            <span className={cx('freeTxt', 'textBrand')}>
                                                                {commonLocalization[GENERIC_L10N.FREE]}
                                                            </span>
                                                        )}{' '}
                                                        <span translate='no'>{item.product.name}</span>
                                                    </Link>
                                                    {/*{item.avaliablity}*/}
                                                    <span>{item.product.codeShort}</span>
                                                </h2>
                                            </div>
                                            {isPricingAvailable && (
                                                <div
                                                    className={cx(
                                                        'col3',
                                                        'md2',
                                                        'sm6',
                                                        'qtyTxt',
                                                        'hiddenLg',
                                                        'hiddenMd',
                                                        'hiddenXl',
                                                        enabledEurConversion ? 'bulgarianCurrencyCol' : ''
                                                    )}
                                                >
                                                    <div
                                                        automation-id='at-price-inclusives-tax'
                                                        className={cx(
                                                            'listItemhead',
                                                            'hiddenLg',
                                                            'hiddenMd',
                                                            'hiddenXl'
                                                        )}
                                                    >
                                                        {localization[ORDERS_L10N.PRICE_TEXT]}
                                                    </div>
                                                    {itemPrice}
                                                    {enabledEurConversion && (
                                                        <p>{convertBGNToEUR(totalPrice.value)}</p>
                                                    )}
                                                </div>
                                            )}
                                            <div
                                                className={cx(
                                                    isPricingAvailable ? 'col2' : 'col2',
                                                    isPricingAvailable ? 'md2' : 'md2',
                                                    'sm6',
                                                    'qtyTxt'
                                                )}
                                            >
                                                <div className={cx('listItemhead', 'hiddenLg', 'hiddenMd', 'hiddenXl')}>
                                                    {localization[ORDERS_L10N.QUANTITY]}
                                                </div>
                                                {item.quantity}
                                            </div>
                                            {isPricingAvailable && (
                                                <div className={cx('col3', 'md2', 'sm6', 'qtyTxt', 'hiddenSm', enabledEurConversion ? 'bulgarianCurrencyCol' : '')}>
                                                    <div
                                                        automation-id='at-price-inclusives-tax'
                                                        className={cx(
                                                            'listItemhead',
                                                            'hiddenLg',
                                                            'hiddenMd',
                                                            'hiddenXl',
                                                            'priceDetails'
                                                        )}
                                                    >
                                                        {localization[ORDERS_L10N.PRICE_TEXT]}
                                                    </div>
                                                    {itemPrice}
                                                    {enabledEurConversion && (
                                                    <p>{convertBGNToEUR(totalPrice.value)}</p>
                                                    )}
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                    {isPricingAvailable && (
                        <div className={cx('detailsright', 'col3', 'md4', 'sm12')}>
                            <div className={cx('pricebreakdown')} id='pricebreakdown'>
                                <h4>
                                    <span className={cx('hiddenSm')}>
                                        <Icon
                                            className={cx('pricedeesktopIcon')}
                                            width='24px'
                                            height='24px'
                                            viewBox='0 0 24 24'
                                            name='financial-info'
                                        />
                                        {localization[ORDERS_L10N.PRICE_BREAKDOWN]}
                                    </span>
                                    <button
                                        type='button'
                                        aria-label='Price Breakdown'
                                        onClick={breakdownOpen}
                                        className={cx('pricebreakdownup', 'hiddenLg', 'hiddenMd', 'hiddenXl')}
                                    >
                                        <Icon
                                            className={cx('pricemobileIcon')}
                                            width='32px'
                                            height='32px'
                                            viewBox='0 0 32 32'
                                            name='order-history'
                                        />
                                        {localization[ORDERS_L10N.PRICE_BREAKDOWN]}
                                        <span className={cx('rightArrow')}>
                                            <Icon
                                                iconType='svg'
                                                width='24px'
                                                height='24px'
                                                viewBox='0 0 24 24'
                                                name='up'
                                            />
                                        </span>
                                    </button>
                                </h4>
                                <div className={cx('pricebreakdowninner')}>
                                    <ul className={cx('pricebreakdownList')}>
                                        {cchPricingData &&
                                            cchPricingData.pricingInfoStyle === CART_PRICING_TYPES.SAVINGS &&
                                            !_isEmpty(cchPricingData.yourSavings) && (
                                                <li automation-id='at-subtotal-text' className={cx('clearfix')}>
                                                    {commonLocalization[GENERIC_L10N.YOUR_SAVINGS]}
                                                    <span>{formatHungaryTousends(cchPricingData.yourSavings.formattedValue)}</span>
                                                </li>
                                            )}

                                        {cchPricingData && cchPricingData.depositFee &&
                                            <li automation-id='at-subtotal-text' className={cx('clearfix')}>
                                                    {commonLocalization[GENERIC_L10N.DEPOSIT_FEE]}
                                                    <span>{formatHungaryTousends(cchPricingData.depositFee.formattedValue)}</span>
                                            </li>
                                        }
                                        {orderData.totalTax &&
                                            <li automation-id='at-subtotal-text' className={cx('clearfix')}>
                                                {commonLocalization[GENERIC_L10N.TOTAL_TAX]}
                                                <span>{formatHungaryTousends(orderData.totalTax.formattedValue)}</span>
                                            </li>
                                        }
                                        <li
                                            automation-id='at-subtotal-text'
                                            className={cx('clearfix', 'breakdownsubtoal')}
                                        >
                                            {localization[ORDERS_L10N.SUB_TOTAL]}
                                            <span>{orderData.subTotal && `${formatHungaryTousends(orderData.subTotal.formattedValue)}`}</span>
                                        </li>
                                        <li
                                            automation-id='at-total-text'
                                            className={cx('clearfix', 'breakdowntotal', 'textBrand')}
                                        >
                                            {localization[ORDERS_L10N.TOTAL]}{' '}
                                            <div className={cx('bulgarianCurrencyCol')}>
                                                <span>
                                                    {orderData.totalPriceWithTax && `${formatHungaryTousends(orderData.totalPriceWithTax.formattedValue)}`}
                                                </span>
                                                {enabledEurConversion && (
                                                    <p className={cx('conversionFontBold')}>{convertBGNToEUR(orderData.totalPriceWithTax.value)}</p>
                                                )}
                                            </div>
                                        </li>
                                        {cchPricingData &&
                                            cchPricingData.pricingConditions &&
                                            cchPricingData.pricingInfoStyle === CART_PRICING_TYPES.PRICING &&
                                            cchPricingData.pricingConditions.map(item => {
                                                return (
                                                    <li key={item.key} className={cx('clearfix')}>
                                                        {trimString(item.key)}
                                                        <span>{formatHungaryTousends(item.value.formattedValue)}</span>
                                                    </li>
                                                );
                                        })}
                                    </ul>
                                    {isReOrderable && (
                                        <Reorder
                                            orderId={orderData.code}
                                            customReorderStyle={cx('reOrderBtn')}
                                            buttonValue={localization[ORDERS_L10N.REORDER_BTN]}
                                            automationId='at-reorder-button'
                                            modalLocalization={{
                                                cancelBtn: localization[ORDERS_L10N.REORDER_POPUP_CANCEL_BUTTON],
                                                checkoutBtn: localization[ORDERS_L10N.REORDER_POPUP_CHECKOUT_BUTTON],
                                                header: localization[ORDERS_L10N.REORDER_POPUP_HEADER_TEXT],
                                                subtext: localization[ORDERS_L10N.REORDER_POPUP_SUBTEXT],
                                                warning: localization[ORDERS_L10N.REORDER_POPUP_SUBTEXT_WARNING],
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
const mapStateToProps = state => {
    return {
        orderDetailData: state.orderDetailData,
        localization: _get(state, 'pageContent.localization'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

export default connect(mapStateToProps)(OrderDetailComponent);
