import Storage, { LocalStorage } from '../Storage/Storage';
import { LOGIN_INFO, UNIQUE_ID, CART_L10N, LOGOUNT_INFO } from '../Constants';
import Cookies from 'universal-cookie';
import { generateUUID, loadCDCSDK } from '../Utils/Utils';
import { store } from '../../../__runtime/redux/Store';

/**
 * To get the currency code/symbol from the presered user info details.
 */
export const getCurrency = () => {
    const userInfo = Storage.getData(LOGIN_INFO.USER_INFO) || {};

    return userInfo.currency || {};
};

export const getLogoutReason = () => {
    return Storage.getData(LOGOUNT_INFO.LOGOUT_REASON);
};

export const getSmartOrderMessagesHome = () => {
    return Storage.getData(LOGOUNT_INFO.SMART_ORDER_MSG_HOME) !== null
        ? Storage.getData(LOGOUNT_INFO.SMART_ORDER_MSG_HOME)
        : true;
};

export const getSmartOrderMessagesPLP = () => {
    return Storage.getData(LOGOUNT_INFO.SMART_ORDER_MSG_PLP) !== null
        ? Storage.getData(LOGOUNT_INFO.SMART_ORDER_MSG_PLP)
        : true;
};

export const getLocalization = () => {
    return LocalStorage.getData(LOGIN_INFO.L10N, true) || {};
};

export const getCountryLocaleCode = () => {
    return getLocalization().lang || {};
};

export const getCountryLocaleCodeIso = () => {
    return getLocalization().oneTrustIsoCode || {};
};

export const getCountry = () => {
    return getLocalization().country || {};
};

export const getLanguage = () => {
    return getLocalization().language || {};
};

export const getActiveCartId = () => {
    return Storage.getData(LOGIN_INFO.CART_ID);
};

export const getSelfiRedCartMessage = () => {
    return Storage.getData(CART_L10N.SELFIRED_CART_MESSAGE);
};

export const getPromoDisclaimerMessage = () => {
    return Storage.getData(CART_L10N.PROMO_DISCLAIMER_MESSAGE);
};

export const getLoyaltyCartMessage = () => {
    return Storage.getData(CART_L10N.LOYALTY_CART_MESSAGE);
};
export const getActiveWholeSaler = () => {
    return Storage.getData(LOGIN_INFO.ACTIVE_WHOLE_SALER);
};

export const getDeliveryType = () => {
    return Storage.getData(LOGIN_INFO.DELIVERY_TYPE);
};

export const getDefaultDeliverDate = () => {
    return Storage.getData(LOGIN_INFO.DELIVERY_DATES);
};

export const getSiteId = () => {
    return getLocalization()[LOGIN_INFO.SITE_ID];
};

export const getUserInfo = () => {
    return Storage.getData(LOGIN_INFO.USER_INFO) || {};
};

export const getChatBotEnabled = () => {
    return Storage.getData(LOGIN_INFO.CHAT_BOT_ENABLED) || false;
}

export const getUserChatActivated = () => {
    return Storage.getData('CHAT_LOADED');
};

export const setUserChatActivated = val => {
    let ev = new Event('CHAT_STATE_UPDATED');
    ev.value = val;
    document.dispatchEvent(ev);
    Storage.setData('CHAT_LOADED', val);
};

export const getUserId = () => {
    const userId = Storage.getData(LOGIN_INFO.TOKEN_USER_ID) || getUserInfo()[UNIQUE_ID];
    return userId;
};

export const getCustomerId = () => {
    return (Storage.getData(LOGIN_INFO.USER_INFO) || {})?.customerId;
};

export const getUserName = () => {
    return getUserInfo().name || {};
};

export const getUserEmail = () => {
    const userInfo = Storage.getData(LOGIN_INFO.USER_INFO) || {};
    return userInfo.email;
};

export const getUserPreferences = key => {
    const userPreferences = Storage.getData(`${getUserId()}_${getSiteId()}`, true) || {};
    return key ? userPreferences[key] : userPreferences;
};

/**
 * To get access/refresh token based on the given token type.
 * If all the token information is required 'isAll' can be passed as true.
 *
 * @param {string} tokenType
 * @param {boolean} isAll
 */
export const getToken = (isAll = false, tokenType = LOGIN_INFO.ACCESS_TOKEN) => {
    if (isAll) {
        return {
            [LOGIN_INFO.ACCESS_TOKEN]: Storage.getData(LOGIN_INFO.ACCESS_TOKEN),
            [LOGIN_INFO.REFRESH_TOKEN]: Storage.getData(LOGIN_INFO.REFRESH_TOKEN),
            [LOGIN_INFO.EXPIRES_IN]: Storage.getData(LOGIN_INFO.EXPIRES_IN),
            [LOGIN_INFO.DEVICE_UUID]: Storage.getData(LOGIN_INFO.DEVICE_UUID, true)
        };
    }
    return Storage.getData(tokenType);
};

export const getRandomUUID = () => {
    return Storage.getData(LOGIN_INFO.RANDOM_UUID, true);
};

export const getInitialDeliveryDate = () => {
    return Storage.getData(LOGIN_INFO.INITIAL_DELIVERY_DATE) || "";
};

export const getDeliveryDatePreference = () => {
    return Storage.getData(LOGIN_INFO.DELIVERY_DATE_PREFERENCE) || "";
};

export const isIndirectUser = () => {
    return Storage.getData(LOGIN_INFO.IS_INDIRECT_USER);
};

export const isPickUpAllowed = () => {
    return !!Storage.getData(LOGIN_INFO.IS_PICKUP_ALLOWED);
};

export const isZADOrderType = () => {
    return !!Storage.getData(LOGIN_INFO.IS_ZAD_ORDER_TYPE);
};

export const isShowCookieConsent = () => {
    return !LocalStorage.getData(LOGIN_INFO.COOKIE_CONSENT_STATUS, true);
};

export const isLocalizationSelected = () => {
    const { L10N, SITE_ID, LANG } = LOGIN_INFO;
    const localization = LocalStorage.getData(L10N, true) || {};

    const cookies = new Cookies();
    const siteIdCookie = cookies.get(LOGIN_INFO.SITE_ID);
    return localization[SITE_ID] && localization[LANG] && siteIdCookie;
};

export const isReturnEmpties = () => {
    return !!Storage.getData(LOGIN_INFO.IS_RETURN_EMPTIES);
};

export const isUseMarketingMaterials = () => {
    return !!Storage.getData(LOGIN_INFO.IS_MARKETING_MATERIALS);
};

export const isOrderSplitIndirect = () => {
    return !!Storage.getData(LOGIN_INFO.IS_ORDER_SPLIT_INDIRECT);
};

export const isBdaaActive = () => {
    return !!Storage.getData(LOGIN_INFO.IS_BDAA_ACTIVE);
};

export const removingOosProductsEnabled = () => {
    return !!Storage.getData(LOGIN_INFO.REMOVING_OOS_PRODUCTS_ENABLED);
};

export const isStockAllowed = () => {
    return !!Storage.getData(LOGIN_INFO.IS_STOCK_ALLOWED);
};

export const getExcelOrderUpload = () => {
    return !!Storage.getData(LOGIN_INFO.EXCEL_ORDER_ULOAD);
};

export const mainPartnerId = () => {
    return Storage.getData(LOGIN_INFO.MAIN_PARTNER_ID);
};

export const mainPartnerCode = () => {
    return Storage.getData(LOGIN_INFO.MAIN_PARTNER_COUNTRY);
};

export const getMiniCartWorkingWay = () => {
    return Storage.getData(LOGIN_INFO.MINI_CART_WORKING_WAY) || false;
};

export const isUserLoggedIn = () => {
    return !!getToken();
};

export const isLoadedPrices = () => {
    return Storage.getData(LOGIN_INFO.LOADED_PRICES);
};

export const getEnabledEURConversion = () => {
    return Storage.getData(LOGIN_INFO.ENABLED_EUR_CONVERSION);
};

export const logout = callback => {
    loadCDCSDK(store.getState()).then(() => {
        gigya.accounts.logout({ callback });
    });
    const showSmartOrderMessagesPLP = getSmartOrderMessagesPLP();
    const showSmartOrderMessagesHOME = getSmartOrderMessagesHome();
    Storage.removeData('__cchbc', true);
    setSmartOrderMessagesHome(showSmartOrderMessagesHOME);
    setSmartOrderMessagesPLP(showSmartOrderMessagesPLP);
};

export const setLogoutReason = reason => {
    Storage.setData(LOGOUNT_INFO.LOGOUT_REASON, reason);
};

export const setSmartOrderMessagesHome = show => {
    Storage.setData(LOGOUNT_INFO.SMART_ORDER_MSG_HOME, show);
};

export const setSmartOrderMessagesPLP = show => {
    Storage.setData(LOGOUNT_INFO.SMART_ORDER_MSG_PLP, show);
};

export const setActiveCartId = cartId => {
    Storage.setData(LOGIN_INFO.CART_ID, cartId);
};

export const setReturnEmpties = isReturnEmpties => {
    Storage.setData(LOGIN_INFO.IS_RETURN_EMPTIES, isReturnEmpties);
};

export const setMarketingMaterials = isMarketingMaterials => {
    Storage.setData(LOGIN_INFO.IS_MARKETING_MATERIALS, isMarketingMaterials);
};

export const setActiveWholeSaler = id => {
    Storage.setData(LOGIN_INFO.ACTIVE_WHOLE_SALER, id);
};

export const setCookieConsentStatus = (status = true) => {
    LocalStorage.setData(LOGIN_INFO.COOKIE_CONSENT_STATUS, status, true);
};

export const setInitialDeliveryDate = date => {
    Storage.setData(LOGIN_INFO.INITIAL_DELIVERY_DATE, date);
};

export const setDeliveryDatePreference = (preference) => {
    return Storage.setData(LOGIN_INFO.DELIVERY_DATE_PREFERENCE, preference);
};

export const setLocalization = (l10N = {}) => {
    // To reset all the storage content when country / language is selected.
    logout();
    LocalStorage.setData(LOGIN_INFO.L10N, l10N, true);

    const cookies = new Cookies();
    const current = new Date();
    const expirationYear = new Date();

    expirationYear.setFullYear(current.getFullYear() + 5);

    // This is needed for the reverse proxy to identify which country and lang did the user selected.
    cookies.set(LOGIN_INFO.SITE_ID, l10N.siteId, {
        path: '/',
        expires: expirationYear
    });
};

export const setDeliveryType = type => {
    Storage.setData(LOGIN_INFO.DELIVERY_TYPE, type);
};

export const setLoadedPrices = type => {
    Storage.setData(LOGIN_INFO.LOADED_PRICES, type);
};

export const setTokenInfo = (tokenInfo = {}) => {
    Storage.setData(LOGIN_INFO.ACCESS_TOKEN, tokenInfo[LOGIN_INFO.ACCESS_TOKEN]);
    Storage.setData(LOGIN_INFO.REFRESH_TOKEN, tokenInfo[LOGIN_INFO.REFRESH_TOKEN]);
    Storage.setData(LOGIN_INFO.EXPIRES_IN, tokenInfo[LOGIN_INFO.EXPIRES_IN]);
    Storage.setData(LOGIN_INFO.TOKEN_USER_ID, tokenInfo[LOGIN_INFO.TOKEN_USER_ID]);

    const randomUUID = getRandomUUID();

    if (!randomUUID) {
        const randUuid = generateUUID();
        Storage.setData(LOGIN_INFO.RANDOM_UUID, randUuid, true);
    }
};

/**
 * To set the loggedIn user information.
 *
 * @param {object} userInfo
 */
export const setUserInfo = (userInfo = {}) => {
    Storage.setData(LOGIN_INFO.USER_INFO, userInfo);
};

export const setCustomerGroup = (code = '') => {
    Storage.setData(LOGIN_INFO.CUST_GROUP_CODE, code);
};

export const setUserType = type => {
    Storage.setData(LOGIN_INFO.IS_INDIRECT_USER, type);
};

export const setDefaultDeliveryDate = date => {
    Storage.setData(LOGIN_INFO.DELIVERY_DATES, date);
};

export const setPickUpAllowed = isAllowed => {
    Storage.setData(LOGIN_INFO.IS_PICKUP_ALLOWED, isAllowed);
};

export const setMiniCartWorkingWay = miniCartWorkingWay => {
    Storage.setData(LOGIN_INFO.MINI_CART_WORKING_WAY, miniCartWorkingWay);
};

export const setZADOrderType = isZADOrder => {
    Storage.setData(LOGIN_INFO.IS_ZAD_ORDER_TYPE, isZADOrder);
};

export const setOrderSplitIndirect = isOrderSplitInd => {
    Storage.setData(LOGIN_INFO.IS_ORDER_SPLIT_INDIRECT, isOrderSplitInd);
};

export const setBdaaActive = isBdaaActive => {
    Storage.setData(LOGIN_INFO.IS_BDAA_ACTIVE, isBdaaActive);
};

export const setChatBotEnabled = isChatBotEnabled => {
    Storage.setData(LOGIN_INFO.CHAT_BOT_ENABLED, isChatBotEnabled);
};

export const setRemovingOosProductsEnabled = removingOosProductsEnabled => {
    Storage.setData(LOGIN_INFO.REMOVING_OOS_PRODUCTS_ENABLED, removingOosProductsEnabled);
};

export const setStockAllowed = isStockAllowed => {
    Storage.setData(LOGIN_INFO.IS_STOCK_ALLOWED, isStockAllowed);
};

export const setExcelOrderUpload = excelOrderUpload => {
    Storage.setData(LOGIN_INFO.EXCEL_ORDER_ULOAD, excelOrderUpload);
};

export const setMainParterId = mainParterId => {
    Storage.setData(LOGIN_INFO.MAIN_PARTNER_ID, mainParterId);
};

export const setMainPartnerCountry = mainPartnerCountry => {
    Storage.setData(LOGIN_INFO.MAIN_PARTNER_COUNTRY, mainPartnerCountry);
};

export const setUserPreferences = (key, value) => {
    const userPreferences = getUserPreferences();
    userPreferences[key] = value;
    Storage.setData(`${getUserId()}_${getSiteId()}`, userPreferences, true);
};

export const setSelfiRedCartMessage = selfiRedDiscountAddedMessage => {
    return Storage.setData(CART_L10N.SELFIRED_CART_MESSAGE, selfiRedDiscountAddedMessage);
};

export const setPromoDisclaimerMessage = promoDisclaimerMessage => {
    return Storage.setData(CART_L10N.PROMO_DISCLAIMER_MESSAGE, promoDisclaimerMessage);
};

export const setLoyaltyCartMessage = loyaltyDiscountAddedMessage => {
    return Storage.setData(CART_L10N.LOYALTY_CART_MESSAGE, loyaltyDiscountAddedMessage);
};

export const setLoyaltyEnabled = loyaltyEnabled => {
    return Storage.setData(LOGIN_INFO.LOYALTY_ENABLED, loyaltyEnabled);
};

export const setEnabledEURConversion = enabledEURConversion => {
    return Storage.setData(LOGIN_INFO.ENABLED_EUR_CONVERSION, enabledEURConversion);
};

export const getBdaaRecommendedCheckboxInfo = () => {
    const data = Storage.getData(LOGIN_INFO.BDAA_RECOMMENDED_INFO);
    return data ? JSON.parse(data) : {};
};

export const setBdaaRecommendedCheckboxInfo = (bdaaInfo) => {
    const currentData = getBdaaRecommendedCheckboxInfo() || {};
    const updatedData = { ...currentData, ...bdaaInfo };
    Storage.setData(LOGIN_INFO.BDAA_RECOMMENDED_INFO, JSON.stringify(updatedData));
}

export const clearBdaaRecommendedCheckboxInfo = () => {
    Storage.removeData(LOGIN_INFO.BDAA_RECOMMENDED_INFO);
};
