/* eslint-disable import/prefer-default-export */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';
import { Link } from 'react-router';
import _get from 'lodash/get';
import * as styles from './ProductCardListView.css';
import SelectBox from '../../core/SelectBox/SelectBox';
import promoTag from '../../../assets/images/Promo-icon2.svg';
import AddToCart from '../AddToCart/AddToCart';
import { PLP_L10N, HOME_L10N, GENERIC_L10N, IMAGE_COMING_SOON, STOCK_LEVELS } from '../../../common/Constants';
import { ProductCardsWater } from './ProductCardsWater';
import Image from '../../core/Image/Image';
import Icon from '../../core/Icon/Icon';
import {
    clearBdaaRecommendedCheckboxInfo,
    getBdaaRecommendedCheckboxInfo,
    getEnabledEURConversion,
    mainPartnerCode,
    setBdaaRecommendedCheckboxInfo
} from '../../../common/UserManager/UserManager';
import Checkbox from '../../core/Checkbox/Checkbox';
import { convertBGNToEUR } from '../../../common/Utils/Utils';

const cx = classNames.bind(styles);

export const ProductCardListView = props => {
    const bdaaLocalStorageData = getBdaaRecommendedCheckboxInfo();

    const [isToggledPromoDescription, setIsToggledPromoDescription] = useState(false);
    const [bdaaRecommendedCheckboxInfoLocal, setBdaaRecommendedCheckboxInfoLocal] = useState(bdaaLocalStorageData);

    const mainPartnerCountryCode = mainPartnerCode();
    const enabledEURConversion = getEnabledEURConversion();

    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);
    const miniCartItems = useSelector(state => state.cartDetails.miniCartItems);

    const bdaaLocalStorageRef = useRef();

    const {
        quantity,
        isQtyError,
        handleProductClickEvent,
        goToProductPage,
        productUrl,
        renderDescription,
        updateUnits,
        renderFavTermIcons,
        stateUnits,
        gtmList,
        depositProduct,
        depositProductLogoUrl,
        recommendedQuantity,
        recommendedReason
    } = props;
    const {
        name,
        sku,
        skuId,
        price, // productObj.price
        brand, // productObj.brand
        category, //productObj.cat
        listPrice,
        isShowPrice,
        units,
        imageUrl,
        deviceType,
        isShowFilter,
        isReturnEmpties,
        isMarketingMaterials,
        isFrom,
        url,
        code,
        conversionFactors,
        addToCartMessage,
        localization,
        commonLocalization,
        isPromo,
        isUpdateSap,
        selectedView,
        baseUnit,
        blockAddToCartEnabled,
        addToCartNotifiedButton,
        stockFlag,
        stockNotified,
        waterCategory,
        openStockNotificationModal,
        addToCartNotifyButton,
        promotion,
        isInBdaaSlider,
        updateFilters,
        maxQty
    } = props.parentProps;

    const productPrice = price?.value;

    useEffect(() => {
        if (bdaaLocalStorageData && !_isEqual(bdaaLocalStorageData, bdaaLocalStorageRef.current)) {
            setBdaaRecommendedCheckboxInfoLocal(bdaaLocalStorageData);
            bdaaLocalStorageRef.current = bdaaLocalStorageData;
        }
    }, [bdaaLocalStorageData]);

    useEffect(() => {
        if (miniCartItems.length === 0) {
            clearBdaaRecommendedCheckboxInfo();
        } else {
            const productCodes = Object.keys(bdaaLocalStorageData).map(key => key);
            const newProductCodes = miniCartItems.map(item => item.code);
            // Loop through each product code in productCodes
            // Create an object to hold only the updated data (product codes with checked: false)
            const updatedBdaaInfo = {};

            productCodes.forEach(code => {
                if (!newProductCodes.includes(code)) {
                    // Check if the product exists in localStorageData before modifying it
                    if (bdaaLocalStorageData[code]) {
                        // Set the checked property to false if the product exists in localStorageData
                        updatedBdaaInfo[code] = { checked: false }; // Only include the `checked` property in the update
                    }
                }
            });
            if (Object.keys(updatedBdaaInfo).length > 0) {
                setBdaaRecommendedCheckboxInfo(updatedBdaaInfo);
                // eslint-disable-next-line react/no-did-update-set-state
                setBdaaRecommendedCheckboxInfoLocal({
                    ...bdaaLocalStorageData,
                    ...updatedBdaaInfo
                });
            }
        }
    }, [miniCartItems.length]);

    const timeZone =
        Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ||
        countriesWithTimeZones[mainPartnerCountryCode]?.TimeZones[0];

    const endDate = moment.tz(promotion?.endDate, timeZone);

    const isBdaaRecommendedChecked =
        bdaaRecommendedCheckboxInfoLocal && Object.keys(bdaaRecommendedCheckboxInfoLocal).find(key => key === code);

    const isBdaaRecommendedCheckedValue =
        (bdaaRecommendedCheckboxInfoLocal && bdaaRecommendedCheckboxInfoLocal[isBdaaRecommendedChecked]?.checked) ||
        false;

    return (
        <li
            role='presentation'
            onClick={handleProductClickEvent}
            className={cx('ProductItem', 'ListItem', 'Row', isPromo && 'promoItem')}
        >
            <ProductCardsWater
                deviceType={deviceType}
                mode='list'
                show={waterCategory}
                tooltipMessage={commonLocalization[GENERIC_L10N.WATER_CATEGORY_MESSAGE]}
            />
            <div className={cx('ProductDataContainerList')}>
                {selectedView !== localization[PLP_L10N.COMPACT_VIEW] && (
                    <div
                        role='presentation'
                        className={cx('ProductImageContainer', 'ProductImageContainerList', 'pointer')}
                        onClick={() => goToProductPage(url, skuId || sku.id)}
                    >
                        {_isEmpty(imageUrl) ? (
                            <img src={IMAGE_COMING_SOON} alt='product coming soon' />
                        ) : (
                            <Image automationId='at-product-image' src={imageUrl} alt='product name' />
                        )}
                        {depositProduct && depositProductLogoUrl && (
                            <img
                                src={`${assetBaseUrl}${depositProductLogoUrl}`}
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    width: '35px',
                                    height: '35px',
                                    objectFit: 'contain'
                                }}
                                alt='return_fee_logo'
                            />
                        )}
                    </div>
                )}

                <div className={cx('ProductControlsList')}>
                    <div className={cx('ProductDataContainer')}>
                        <Link to={productUrl()} className={cx('ProdTitle', 'ProdTitleList')}>
                            <span translate='no'>{name}</span>
                        </Link>
                        <p className={cx('skuId', isPromo && 'skuIdPromo')}>
                            <span automation-id='at-product-id'>{commonLocalization[GENERIC_L10N.SKU]}</span>{' '}
                            {skuId || sku.id}
                        </p>
                        <RenderStockQuantity
                            recommendedReason={recommendedReason}
                            stockFlag={stockFlag}
                            commonLocalization={commonLocalization}
                            isList={true}
                        />
                        {isPromo && deviceType.isDesktop && (
                            <div
                                className={cx('promoProductToggleContainer')}
                                onClick={() => setIsToggledPromoDescription(prev => !prev)}
                            >
                                <img src={promoTag} />
                                <p className={cx('promoProductToggle')}>{localization[PLP_L10N.PROMO_PRODUCT_TILE]}</p>
                                <Icon
                                    className={cx(
                                        'svgClass',
                                        'promoProductToggleArrow',
                                        !isToggledPromoDescription && 'rotateDown'
                                    )}
                                    iconType='svg'
                                    width='24px'
                                    height='24px'
                                    viewBox='0 0 24 24'
                                    name='up'
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className={cx('PriceAndTypeContainer', 'PriceAndTypeContainerList', !listPrice && 'flex1')}
                        style={{ alignItems: isShowPrice && enabledEURConversion ? 'flex-start' : 'center' }}
                    >
                        <div className={cx('bulgarianCurrencyCol')}>
                            <p
                                automation-id='at-prices-for-each-item'
                                className={cx('text-right', 'pad5', 'noWrp', isPromo && 'pricePromo')}
                            >
                                {(isShowPrice && listPrice) || ''}
                            </p>
                            {enabledEURConversion && <p>{convertBGNToEUR(productPrice, false)}</p>}
                        </div>
                        <div className={cx('mobhid', 'visibleList')}>
                            <SelectBox
                                arrowTheme={cx('selectOpt')}
                                automationId='at-uom-selection-dropdown'
                                deaultIcon
                                id={skuId || sku.id}
                                onChange={val => {
                                    const item = units.find(({ value }) => value === val);
                                    updateUnits(item);
                                }}
                                options={units}
                                defaultValue={stateUnits.value}
                                disabled={isMarketingMaterials}
                                themeSelectBox={cx('select')}
                            />
                        </div>
                    </div>
                </div>
                <div className={cx('AddToCartControlsContainer')}>
                    {recommendedQuantity && recommendedReason && (
                        <div className={cx('suggestedQtyContainer')}>
                            <Checkbox
                                automation-id='at-checkbox'
                                name='suggested-quanity-checkbox'
                                checked={isBdaaRecommendedCheckedValue}
                                // theme={cx('Checktheme')}
                                themecheckboxCheckmark={cx(
                                    isBdaaRecommendedCheckedValue
                                        ? 'suggestedQtyCheckboxChecked'
                                        : 'suggestedQtyCheckbox'
                                )}
                                onChange={() => {
                                    const existingValue = bdaaRecommendedCheckboxInfoLocal;
                                    if (Object.keys(existingValue).length === 0) {
                                        setBdaaRecommendedCheckboxInfo({
                                            [code]: {
                                                checked: true
                                            }
                                        });
                                        // Directly update state with the product's code as key, keeping the object structure
                                        setBdaaRecommendedCheckboxInfoLocal({
                                            [code]: {
                                                checked: true
                                            }
                                        });
                                    } else {
                                        const data = Object.keys(existingValue).find(key => {
                                            return key === code;
                                        });
                                        if (data) {
                                            // If the product code is found
                                            // Access the existing product info using the product code
                                            const bdaaProductInfo = existingValue[code];

                                            // Toggle the checked status in local storage and state
                                            setBdaaRecommendedCheckboxInfo({
                                                [code]: {
                                                    checked: !bdaaProductInfo.checked // Toggle the checked status
                                                }
                                            });
                                            setBdaaRecommendedCheckboxInfoLocal({
                                                [code]: {
                                                    checked: !bdaaProductInfo.checked // Toggle the checked status
                                                }
                                            });
                                        } else {
                                            setBdaaRecommendedCheckboxInfo({
                                                [code]: {
                                                    checked: true
                                                }
                                            });
                                            setBdaaRecommendedCheckboxInfoLocal({
                                                [code]: {
                                                    checked: true
                                                }
                                            });
                                        }
                                    }
                                }}
                            />
                            <div
                                tabIndex='-1'
                                role='button'
                                className={cx('suggestedQtySubContainer')}
                                onClick={() => {
                                    const existingValue = bdaaRecommendedCheckboxInfoLocal;
                                    if (Object.keys(existingValue).length === 0) {
                                        setBdaaRecommendedCheckboxInfo({
                                            [code]: {
                                                checked: true
                                            }
                                        });
                                        // Directly update state with the product's code as key, keeping the object structure
                                        setBdaaRecommendedCheckboxInfoLocal({
                                            [code]: {
                                                checked: true
                                            }
                                        });
                                    } else {
                                        const data = Object.keys(existingValue).find(key => {
                                            return key === code;
                                        });
                                        if (data) {
                                            // If the product code is found
                                            // Access the existing product info using the product code
                                            const bdaaProductInfo = existingValue[code];

                                            // Toggle the checked status in local storage and state
                                            setBdaaRecommendedCheckboxInfo({
                                                [code]: {
                                                    checked: !bdaaProductInfo.checked // Toggle the checked status
                                                }
                                            });
                                            setBdaaRecommendedCheckboxInfoLocal({
                                                [code]: {
                                                    checked: !bdaaProductInfo.checked // Toggle the checked status
                                                }
                                            });
                                        } else {
                                            setBdaaRecommendedCheckboxInfo({
                                                [code]: {
                                                    checked: true
                                                }
                                            });
                                            setBdaaRecommendedCheckboxInfoLocal({
                                                [code]: {
                                                    checked: true
                                                }
                                            });
                                        }
                                    }
                                }}
                                onKeyDown={() => {
                                    const existingValue = bdaaRecommendedCheckboxInfoLocal;
                                    if (Object.keys(existingValue).length === 0) {
                                        setBdaaRecommendedCheckboxInfo({
                                            [code]: {
                                                checked: true
                                            }
                                        });
                                        // Directly update state with the product's code as key, keeping the object structure
                                        setBdaaRecommendedCheckboxInfoLocal({
                                            [code]: {
                                                checked: true
                                            }
                                        });
                                    } else {
                                        const data = Object.keys(existingValue).find(key => {
                                            return key === code;
                                        });
                                        if (data) {
                                            // If the product code is found
                                            // Access the existing product info using the product code
                                            const bdaaProductInfo = existingValue[code];

                                            // Toggle the checked status in local storage and state
                                            setBdaaRecommendedCheckboxInfo({
                                                [code]: {
                                                    checked: !bdaaProductInfo.checked // Toggle the checked status
                                                }
                                            });
                                            setBdaaRecommendedCheckboxInfoLocal({
                                                [code]: {
                                                    checked: !bdaaProductInfo.checked // Toggle the checked status
                                                }
                                            });
                                        } else {
                                            setBdaaRecommendedCheckboxInfo({
                                                [code]: {
                                                    checked: true
                                                }
                                            });
                                            setBdaaRecommendedCheckboxInfoLocal({
                                                [code]: {
                                                    checked: true
                                                }
                                            });
                                        }
                                    }
                                }}
                            >
                                <Icon
                                    className={cx('flex-icon')}
                                    iconType='svg'
                                    width='25px'
                                    height='25px'
                                    viewBox='-5 -3 20 20'
                                    name='thumbs-up'
                                />
                                <p className={cx('addSuggestedQtyText')}>
                                    {commonLocalization[GENERIC_L10N.ADD_QTY]}: {recommendedQuantity}
                                </p>
                            </div>
                        </div>
                    )}
                    <div className={cx('addToCartContainer')}>
                        <AddToCart
                            isFromProductCardList
                            ignoreSavedCart={isReturnEmpties || isMarketingMaterials}
                            baseUnit={baseUnit}
                            isUpdateSap={isUpdateSap}
                            isQtyError={isQtyError}
                            skuId={skuId || sku.id}
                            automationId='at-add-to-card-button'
                            autoHeight
                            customAddtoCartStyle={cx('btn-addtocart', isShowFilter ? 'hidenAddBtn' : 'filterAddBtn')}
                            iconName='addCart-red'
                            buttonValue={
                                isFrom === 'HOME' && deviceType.isDesktop
                                    ? localization[HOME_L10N.ADD_BTN]
                                    : commonLocalization[GENERIC_L10N.ADD_TO_CART]
                            }
                            productInfo={[
                                {
                                    code,
                                    quantityType: stateUnits.id,
                                    quantity,
                                    conversionFactors
                                }
                            ]}
                            analyticsProductInfo={[
                                {
                                    name,
                                    // id: code.substring(22),
                                    id: skuId || sku.id,
                                    uom: stateUnits.id,
                                    quantity,
                                    list: gtmList,
                                    price: price?.formattedValue || listPrice, // productObj.price
                                    brand, // productObj.brand
                                    category //productObj.cat
                                }
                            ]}
                            stockFlag={stockFlag}
                            stockNotified={stockNotified}
                            openStockNotificationModal={e => openStockNotificationModal()}
                            addToCartMessage={addToCartMessage}
                            addToCartNotifyButton={addToCartNotifyButton}
                            addToCartNotifiedButton={addToCartNotifiedButton}
                            blockAddToCartEnabled={blockAddToCartEnabled}
                            bdaaRecommendedCheckboxInfo={bdaaRecommendedCheckboxInfoLocal}
                        />
                        {!isReturnEmpties && !isMarketingMaterials && renderFavTermIcons(stateUnits)}
                    </div>
                </div>
            </div>
            {quantity === maxQty && (
                <div className={cx('maxQtyWarn')}>
                    <Icon
                        className={cx('maxQtyWarn-icon')}
                        iconType='svg'
                        width='15px'
                        height='16px'
                        viewBox='0 0 15 16'
                        name='warning-list'
                    />
                    <p>{commonLocalization[GENERIC_L10N.STOCK_ALLOCATION_LIMIT_MESSAGE_LONG]}</p>
                </div>
            )}
            <div className={cx('promoToggledDescription', isToggledPromoDescription && 'openPromoToggledDescription')}>
                {promotion?.description && (
                    <div>
                        <p className={cx('promoTitle')}>{promotion.description}</p>
                    </div>
                )}

                <div className={cx('promoCountdownContainer')}>
                    <Countdown
                        date={endDate}
                        renderer={({ days, hours, minutes }) => {
                            return (
                                <div className={cx('promoCountdown')}>
                                    {days}
                                    {commonLocalization?.day} : {hours > 9 ? hours : `0${hours} `}
                                    {commonLocalization?.hour} : {minutes > 9 ? minutes : `0${minutes} `}
                                    {commonLocalization?.minute}
                                </div>
                            );
                        }}
                    />
                    <p className={cx('promoValidFrom')}>
                        <span className={cx('promoValidFromLabel')}>{localization[PLP_L10N.VALID_FROM]}</span>{' '}
                        {promotion?.formattedStartDate}{' '}
                        <span className={cx('promoValidFromLabel')}>{localization[PLP_L10N.VALID_UNTIL]}</span>{' '}
                        {promotion?.formattedEndDate}
                    </p>
                </div>
                <div
                    className={cx('promoCTA')}
                    onClick={e => {
                        updateFilters('promotion', promotion.description, null, true);
                    }}
                >
                    <p>{localization[PLP_L10N.PROMO_VIEW_ALL_PRODUCTS]}</p>

                    <Icon
                        className={cx('svgClass', 'promoProductToggleArrow', 'rotateRight')}
                        iconType='svg'
                        width='24px'
                        height='24px'
                        viewBox='0 0 24 24'
                        name='up'
                    />
                </div>
            </div>
        </li>
    );
};

const RenderStockQuantity = props => {
    const { stockFlag, commonLocalization, recommendedReason } = props;

    if (stockFlag) {
        const isOutOfStock = stockFlag === 'OUT_OF_STOCK';
        return (
            <div
                className={cx(
                    'StockContainer',
                    STOCK_LEVELS[stockFlag].class,
                    'StockContainerList',
                    isOutOfStock && 'OutOfStockList'
                )}
            >
                {isOutOfStock
                    ? commonLocalization[GENERIC_L10N.OUT_OF_STOCK]
                    : commonLocalization[GENERIC_L10N.IN_STOCK]}
                {recommendedReason && ` (${recommendedReason})`}
            </div>
        );
    }
    return <div />;
};
