/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import {
    addToFavouriteEvent,
    removeFavouriteEvent,
    updateBdaaProductFavouriteProp
} from '../../../actions/FavouriteAction';
import { updateTempMiniCart } from '../../../actions/CartAction';

import AddToCart from '../AddToCart/AddToCart';
import Icon from '../../core/Icon/Icon';
import {
    PLP_L10N,
    HOME_L10N,
    GENERIC_L10N,
    TEMP_POPUP_L10N,
    URL_PATHS,
    PAGE_ID,
    IMAGE_COMING_SOON,
    DATA_LAYER_LISTS,
    STOCK_LEVELS,
    PAGE_TYPES
} from '../../../common/Constants';
import AddToOrderTemplate from '../AddToOrderTemplate/AddToOrderTemplate';
import Image from '../../core/Image/Image';
import { convertBGNToEUR, goToPDP } from '../../../common/Utils/Utils';
import * as styles from './ProductCards.css';
import SelectBox from '../../core/SelectBox/SelectBox';
import { store } from '../../../../__runtime/redux/Store';
import { productClickHomePageDataLayerFilterGA4 } from '../../../service/DataLayerFilter';
import {
    productClickEventGA4,
    buttonClickEvent,
    buttonClickEventConfigs
} from '../../../common/GoogleTagManager/GoogleTagManager';
import ProductCardsPromoHeader from './ProductCardsPromoHeader';
import { ProductCardsWater } from './ProductCardsWater';
import { ProductCardListView } from './ProductCardListView';
import { openSubscriptionPopup } from '../../../actions/NotifyMeAction';
import Checkbox from '../../core/Checkbox/Checkbox';
import {
    clearBdaaRecommendedCheckboxInfo,
    getBdaaRecommendedCheckboxInfo,
    getEnabledEURConversion,
    setBdaaRecommendedCheckboxInfo
} from '../../../common/UserManager/UserManager';

const cx = classNames.bind(styles);

const faviLabelIcon = <Icon width='24px' height='24px' viewBox='0 0 24 24' name='promo-badge' />;

export class ProductCards extends Component {
    constructor(props) {
        super(props);
        this.bdaaRecommendedCheckboxInfo = getBdaaRecommendedCheckboxInfo();
        this.enabledEURConversion = getEnabledEURConversion();
        this.state = {
            units: {},
            quantity: props.minQty,
            maxQty: props.maxQty,
            isQtyError: false,
            showNotifyPopup: false,
            displayPromoDetails: false,
            showWater: false,
            bdaaRecommendedCheckboxInfo: this.bdaaRecommendedCheckboxInfo
        };
    }
    static getDerivedStateFromProps(props, state) {
        const { miniCartItems, code, plpMiniCartItems, unitOptions, baseUnit } = props;
        const { units: stateUnits } = state;

        let quantity = 0;
        let units = stateUnits;
        if (Object.keys(units).length === 0 && unitOptions) {
            units = unitOptions.find(({ id }) => id === baseUnit);
        }

        const itemMC = miniCartItems.find(item => item.code === code);

        if (Object.keys(units).length > 0) {
            const item = plpMiniCartItems && plpMiniCartItems[units.id] ? plpMiniCartItems[units.id][code] : null;

            if (item) {
                quantity = item.quantity;
            }

            if (itemMC && itemMC.quantityType === units.id) {
                quantity = itemMC.quantity;
            }

            // if (!item && itemMC && itemMC.quantityType === units.id) {
            //     quantity = itemMC.quantity;
            // }
        }

        return {
            ...state,
            quantity,
            units
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // Fetch the current data from localStorage
        const localStorageData = getBdaaRecommendedCheckboxInfo();
        if (JSON.stringify(prevState.bdaaRecommendedCheckboxInfo) !== JSON.stringify(localStorageData)) {
            // If different, update the state to match localStorage
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                bdaaRecommendedCheckboxInfo: localStorageData
            });
        }
        // Check if minicart items goes to zero. If so, set checked to false inside the local storage object
        if (!_isEqual(prevProps.miniCartItems.length, this.props.miniCartItems.length)) {
            if (this.props.miniCartItems.length === 0) {
                clearBdaaRecommendedCheckboxInfo();
            } else {
                const productCodes = Object.keys(localStorageData).map(key => key);
                const newProductCodes = this.props.miniCartItems.map(item => item.code);
                // Loop through each product code in productCodes
                // Create an object to hold only the updated data (product codes with checked: false)
                const updatedBdaaInfo = {};

                productCodes.forEach(code => {
                    if (!newProductCodes.includes(code)) {
                        // Check if the product exists in localStorageData before modifying it
                        if (localStorageData[code]) {
                            // Set the checked property to false if the product exists in localStorageData
                            updatedBdaaInfo[code] = { checked: false }; // Only include the `checked` property in the update
                        }
                    }
                });
                if (Object.keys(updatedBdaaInfo).length > 0) {
                    setBdaaRecommendedCheckboxInfo(updatedBdaaInfo);
                    // eslint-disable-next-line react/no-did-update-set-state
                    this.setState({
                        bdaaRecommendedCheckboxInfo: {
                            ...localStorageData,
                            ...updatedBdaaInfo
                        }
                    });
                }
            }
        }
    }

    handleProductClickEvent = () => {
        try {
            let list;

            switch (this.props.isFrom) {
                case PAGE_TYPES.HOME:
                    list = DATA_LAYER_LISTS.HOMEPAGE_FAVORITE_LIST;
                    break;
                case PAGE_TYPES.PLP:
                    list = this.props.isSearch ? DATA_LAYER_LISTS.SEARCH_LIST : DATA_LAYER_LISTS.PRODUCT_LIST;
                    break;
                default:
            }

            if (this.props.isInBdaaSlider) {
                list = DATA_LAYER_LISTS.BDAA_LIST;
            }

            productClickEventGA4(productClickHomePageDataLayerFilterGA4(_cloneDeep(this.props)), list);
            if (this.props.isPlpPage) {
                buttonClickEvent(
                    buttonClickEventConfigs.products.actions.productTile,
                    buttonClickEventConfigs.products.labels.productTileProductSelection,
                    buttonClickEventConfigs.products.categories.products
                );
            }
        } catch (ex) {
            console.error(ex);
        }
    };

    updateUnits = val => {
        const { actions, code } = this.props;
        actions.updateTempMiniCart({
            substitute: true,
            quantity: 0,
            quantityType: val.id,
            code
        });

        this.setState({ units: val });
    };

    favClickEvent = (code, isFav) => {
        const { actions, isFrom, product, isPlpPage } = this.props;
        const payload = {
            code,
            from: isFrom,
            isFav,
            product
        };

        if (isFav) {
            actions.removeFavouriteEvent(payload);
            if (isPlpPage) {
                buttonClickEvent(
                    buttonClickEventConfigs.products.actions.productTile,
                    buttonClickEventConfigs.products.labels.productTileRemoveFromFavorites,
                    buttonClickEventConfigs.products.categories.products
                );
            }
        } else {
            actions.addToFavouriteEvent(payload);
            if (isPlpPage) {
                buttonClickEvent(
                    buttonClickEventConfigs.products.actions.productTile,
                    buttonClickEventConfigs.products.labels.productTileAddToFavorites,
                    buttonClickEventConfigs.products.categories.products
                );
            }
        }

        actions.updateBdaaProductFavouriteProp(payload);
    };

    goToProductPage = (url, skuId) => {
        goToPDP(url, skuId);
    };

    renderStockQuantityHeader = () => {
        const { stockFlag, commonLocalization } = this.props;
        const isOutOfStock = stockFlag === 'OUT_OF_STOCK';

        if (!stockFlag) {
            return null;
        }
        return (
            <div className={cx('promoHolder')}>
                <div className={cx('promoHeader', 'cursorDefault', 'inStock', isOutOfStock && 'outOfStock')}>
                    <p className={cx('promoTitle')}>
                        {isOutOfStock
                            ? commonLocalization[GENERIC_L10N.OUT_OF_STOCK]
                            : commonLocalization[GENERIC_L10N.IN_STOCK]}
                    </p>
                </div>
            </div>
        );
    };

    renderFavTermIcons = stateUnits => {
        const { quantity } = this.state;
        const {
            skuId,
            isFav,
            isShowFilter,
            selectedMobileView,
            code,
            localization,
            commonLocalization,
            isGrid,
            isInBdaaSlider,
            isPlpPage
        } = this.props;

        const basicProductDetails = {
            productCode: code,
            quantity: quantity !== undefined ? quantity : 1,
            unitCode: stateUnits.id
        };

        return (
            <div className={cx('favAndTem', isGrid && 'favIconsGrid', !isGrid && 'favIconsList')}>
                <span
                    tabIndex={0}
                    aria-label='fav'
                    role='button'
                    className={cx(
                        'favItem',
                        'pointer',
                        isShowFilter && 'showFavi',
                        selectedMobileView === localization[PLP_L10N.COMPACT_VIEW] && 'compactFavItem'
                    )}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.favClickEvent(code, isFav);
                    }}
                    style={{
                        display: 'block'
                    }}
                    onKeyDown={() => {}}
                    automation-id='at-favourite-icon'
                >
                    <span className={cx('iconTheme')}>
                        <Icon
                            key={`fav_icon_${isFav}_${skuId}`}
                            iconType='svg'
                            width='24px'
                            height='24px'
                            viewBox='0 0 24 24'
                            name={isFav ? 'fav-icon-active' : 'fav_icon'}
                        />
                    </span>
                </span>

                <span
                    className={cx(
                        'tempItem',
                        selectedMobileView === localization[PLP_L10N.COMPACT_VIEW] && 'compacttempItem'
                    )}
                    title={localization[TEMP_POPUP_L10N.ADD_TO_TEMP_TEXT]}
                >
                    {!isInBdaaSlider && (
                        <AddToOrderTemplate
                            automationId='at-add-item-to-order-template-icon'
                            plpCustomStyle={cx('plpCustomStyle')}
                            basicProductDetails={basicProductDetails}
                            modalTitle={commonLocalization[GENERIC_L10N.TEMP_OP_TITLE]}
                            isPlpPage
                        />
                    )}
                </span>
            </div>
        );
    };

    renderDepositFeeIcon = () => {
        const { depositProduct, depositProductLogoUrl, assetBaseUrl } = this.props;
        if (assetBaseUrl && depositProduct && depositProductLogoUrl) {
            return (
                <img
                    className={cx('deposit-fee-logo')}
                    src={`${assetBaseUrl}${depositProductLogoUrl}`}
                    alt='return_fee_logo'
                />
            );
        }
    };

    renderRecommendedReason = () => {
        const { recommendedReason } = this.props;
        return (
            <div className={cx('recommendedReasonContainer')}>
                <Icon
                    className={cx('flex-icon')}
                    iconType='svg'
                    width='22px'
                    height='22px'
                    viewBox='-5 -3 20 20'
                    name='thumbs-up'
                />
                <p className={cx('recommendedReasonText')}>{recommendedReason}</p>
            </div>
        );
    };

    productUrl = () => {
        const { code } = this.props;
        return `${URL_PATHS.PDP}/${code}`;
    };

    renderProductCardGrid = (stateUnits, gtmList) => {
        const { quantity, isQtyError, bdaaRecommendedCheckboxInfo } = this.state;
        const {
            isPromo,
            promotion,
            forwardRef,
            price, // productObj.price
            brand, // productObj.brand
            category, //productObj.cat
            stockFlag,
            stockNotified,
            name,
            waterCategory,
            sku,
            skuId,
            listPrice,
            pricePerPiece,
            isShowPrice,
            units,
            imageUrl,
            deviceType,
            isShowFilter,
            isReturnEmpties,
            isMarketingMaterials,
            isFrom,
            url,
            code,
            conversionFactors,
            addToCartMessage,
            localization,
            commonLocalization,
            isUpdateSap,
            baseUnit,
            blockAddToCartEnabled,
            isInBdaaSlider,
            updateFilters,
            promoPrice,
            isReadOnlyMode,
            maxQty,
            isPlpPage,
            recommendedQuantity,
            recommendedReason
        } = this.props;

        const isOutOfStock = stockFlag === 'OUT_OF_STOCK';
        let heightOffset = '0px';

        const isBdaaRecommendedChecked = Object.keys(bdaaRecommendedCheckboxInfo).find(key => key === code);

        const isBdaaRecommendedCheckedValue = bdaaRecommendedCheckboxInfo[isBdaaRecommendedChecked]?.checked || false;

        const productPrice = price?.value;

        if (!isOutOfStock && isPromo) {
            heightOffset = '50px';
        }

        if ((!isPromo || (isPromo && isOutOfStock)) && stockFlag) {
            heightOffset = '50px';
        }
        return (
            <li
                ref={forwardRef}
                className={cx(
                    'ProductItem',
                    skuId,
                    isPromo ? 'hasPromo' : '',
                    isReadOnlyMode ? 'ProductItem__compact' : '',
                    isPlpPage ? 'removeMargin' : ''
                )}
                key={skuId}
                role='presentation'
                onClick={this.handleProductClickEvent}
            >
                <ProductCardsWater
                    deviceType={deviceType}
                    mode='grid'
                    heightOffset={heightOffset}
                    show={waterCategory}
                    tooltipMessage={commonLocalization[GENERIC_L10N.WATER_CATEGORY_MESSAGE]}
                />
                {!isOutOfStock && (
                    <ProductCardsPromoHeader
                        isPromo={isPromo}
                        skuId={skuId}
                        promotion={promotion}
                        localization={localization}
                        isInBdaaSlider={isInBdaaSlider}
                        isFrom={isFrom}
                        updateFilters={updateFilters}
                    />
                )}

                {(!isPromo || (isPromo && isOutOfStock)) && this.renderStockQuantityHeader()}

                <div
                    role='presentation'
                    className={cx('ProductImageContainer', 'pointer')}
                    onClick={() => this.goToProductPage(url, skuId)}
                >
                    {_isEmpty(imageUrl) ? (
                        <img src={IMAGE_COMING_SOON} alt='product coming soon' />
                    ) : (
                        <Image automationId='at-product-image' src={imageUrl} alt='product name' />
                    )}

                    {!isReturnEmpties &&
                        !isMarketingMaterials &&
                        !isReadOnlyMode &&
                        this.renderFavTermIcons(stateUnits)}
                    {this.renderDepositFeeIcon()}
                    {recommendedQuantity && recommendedReason && this.renderRecommendedReason()}
                </div>
                <div className={cx('ProductDataContainer', !stockFlag && 'ProductDataContainerNoStockField')}>
                    <div className={cx('productInfoContainer')}>
                        <div>
                            <Link to={this.productUrl()} className={cx('ProdTitle')}>
                                <span translate='no'>{name}</span>
                            </Link>
                            <div className={cx('skuWrapper')}>
                                {isReadOnlyMode ? (
                                    <div className={cx('skuReadOnly')}>{stateUnits.value}</div>
                                ) : (
                                    <div className={cx('mobhid', 'visibleList')}>
                                        {/* <ToggleButtons
                                        options={units}
                                        selectedItem={stateUnits}
                                        onChange={this.updateUnits}
                                    /> */}
                                        <SelectBox
                                            arrowTheme={cx('selectOpt')}
                                            automationId='at-uom-selection-dropdown'
                                            deaultIcon
                                            id={skuId || sku.id}
                                            onChange={val => {
                                                const item = units.find(({ value }) => value === val);
                                                this.updateUnits(item);
                                            }}
                                            options={isReadOnlyMode ? [stateUnits] : units}
                                            defaultValue={stateUnits.value}
                                            disabled={isMarketingMaterials || isReadOnlyMode}
                                            onFocus={() => {
                                                if (this.props.isPlpPage) {
                                                    buttonClickEvent(
                                                        buttonClickEventConfigs.products.actions.productTile,
                                                        buttonClickEventConfigs.products.labels
                                                            .productTileLogisticUnitSelection,
                                                        buttonClickEventConfigs.products.categories.products
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                <p className={cx('skuId')}>
                                    <span automation-id='at-product-id'>{commonLocalization[GENERIC_L10N.SKU]}</span>{' '}
                                    {skuId || sku.id}
                                </p>
                            </div>
                        </div>

                        <div className={cx('AddToCartControlsContainer')}>
                            {recommendedQuantity && recommendedReason && (
                                <div className={cx('suggestedQtyContainer')}>
                                    <Checkbox
                                        automation-id='at-checkbox'
                                        name='suggested-quanity-checkbox'
                                        checked={isBdaaRecommendedCheckedValue}
                                        // theme={cx('Checktheme')}
                                        themecheckboxCheckmark={cx(
                                            isBdaaRecommendedCheckedValue
                                                ? 'suggestedQtyCheckboxChecked'
                                                : 'suggestedQtyCheckbox'
                                        )}
                                        onChange={() => {
                                            const { bdaaRecommendedCheckboxInfo } = this.state;
                                            const existingValue = bdaaRecommendedCheckboxInfo;
                                            if (Object.keys(existingValue).length === 0) {
                                                setBdaaRecommendedCheckboxInfo({
                                                    [code]: {
                                                        checked: true
                                                    }
                                                });
                                                // Directly update state with the product's code as key, keeping the object structure
                                                this.setState({
                                                    bdaaRecommendedCheckboxInfo: {
                                                        [code]: {
                                                            checked: true
                                                        }
                                                    }
                                                });
                                            } else {
                                                const data = Object.keys(existingValue).find(key => {
                                                    return key === code;
                                                });
                                                if (data) {
                                                    // If the product code is found
                                                    // Access the existing product info using the product code
                                                    const bdaaProductInfo = existingValue[code];

                                                    // Toggle the checked status in local storage and state
                                                    setBdaaRecommendedCheckboxInfo({
                                                        [code]: {
                                                            checked: !bdaaProductInfo.checked // Toggle the checked status
                                                        }
                                                    });
                                                    this.setState({
                                                        bdaaRecommendedCheckboxInfo: {
                                                            [code]: {
                                                                checked: !bdaaProductInfo.checked // Toggle the checked status
                                                            }
                                                        }
                                                    });
                                                } else {
                                                    setBdaaRecommendedCheckboxInfo({
                                                        [code]: {
                                                            checked: true
                                                        }
                                                    });
                                                    this.setState({
                                                        bdaaRecommendedCheckboxInfo: {
                                                            [code]: {
                                                                checked: true
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                    <div
                                        tabIndex='-1'
                                        role='button'
                                        className={cx('suggestedQtySubContainer')}
                                        onClick={() => {
                                            const { bdaaRecommendedCheckboxInfo } = this.state;
                                            const existingValue = bdaaRecommendedCheckboxInfo;
                                            if (Object.keys(existingValue).length === 0) {
                                                setBdaaRecommendedCheckboxInfo({
                                                    [code]: {
                                                        checked: true
                                                    }
                                                });
                                                // Directly update state with the product's code as key, keeping the object structure
                                                this.setState({
                                                    bdaaRecommendedCheckboxInfo: {
                                                        [code]: {
                                                            checked: true
                                                        }
                                                    }
                                                });
                                            } else {
                                                const data = Object.keys(existingValue).find(key => {
                                                    return key === code;
                                                });
                                                if (data) {
                                                    // If the product code is found
                                                    // Access the existing product info using the product code
                                                    const bdaaProductInfo = existingValue[code];

                                                    // Toggle the checked status in local storage and state
                                                    setBdaaRecommendedCheckboxInfo({
                                                        [code]: {
                                                            checked: !bdaaProductInfo.checked // Toggle the checked status
                                                        }
                                                    });
                                                    this.setState({
                                                        bdaaRecommendedCheckboxInfo: {
                                                            [code]: {
                                                                checked: !bdaaProductInfo.checked // Toggle the checked status
                                                            }
                                                        }
                                                    });
                                                } else {
                                                    setBdaaRecommendedCheckboxInfo({
                                                        [code]: {
                                                            checked: true
                                                        }
                                                    });
                                                    this.setState({
                                                        bdaaRecommendedCheckboxInfo: {
                                                            [code]: {
                                                                checked: true
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        }}
                                        onKeyDown={() => {
                                            const { bdaaRecommendedCheckboxInfo } = this.state;
                                            const existingValue = bdaaRecommendedCheckboxInfo;
                                            if (Object.keys(existingValue).length === 0) {
                                                setBdaaRecommendedCheckboxInfo({
                                                    [code]: {
                                                        checked: true
                                                    }
                                                });
                                                // Directly update state with the product's code as key, keeping the object structure
                                                this.setState({
                                                    bdaaRecommendedCheckboxInfo: {
                                                        [code]: {
                                                            checked: true
                                                        }
                                                    }
                                                });
                                            } else {
                                                const data = Object.keys(existingValue).find(key => {
                                                    return key === code;
                                                });
                                                if (data) {
                                                    // If the product code is found
                                                    // Access the existing product info using the product code
                                                    const bdaaProductInfo = existingValue[code];

                                                    // Toggle the checked status in local storage and state
                                                    setBdaaRecommendedCheckboxInfo({
                                                        [code]: {
                                                            checked: !bdaaProductInfo.checked // Toggle the checked status
                                                        }
                                                    });
                                                    this.setState({
                                                        bdaaRecommendedCheckboxInfo: {
                                                            [code]: {
                                                                checked: !bdaaProductInfo.checked // Toggle the checked status
                                                            }
                                                        }
                                                    });
                                                } else {
                                                    setBdaaRecommendedCheckboxInfo({
                                                        [code]: {
                                                            checked: true
                                                        }
                                                    });
                                                    this.setState({
                                                        bdaaRecommendedCheckboxInfo: {
                                                            [code]: {
                                                                checked: true
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        <Icon
                                            className={cx('flex-icon')}
                                            iconType='svg'
                                            width='25px'
                                            height='25px'
                                            viewBox='-5 -3 20 20'
                                            name='thumbs-up'
                                        />
                                        <p className={cx('addSuggestedQtyText')}>
                                            {commonLocalization[GENERIC_L10N.ADD_QTY]}: {recommendedQuantity}
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div
                                className={cx(
                                    'PriceAndTypeContainer',
                                    pricePerPiece ? 'PriceAndTypeContainer__isPerPiecePrice' : ''
                                )}
                            >
                                {Boolean(isShowPrice && pricePerPiece) && (
                                    <div className={cx('pricePerPiece')}>{pricePerPiece}</div>
                                )}
                                <div className={cx('bulgarianCurrencyRow')}>
                                    <p automation-id='at-prices-for-each-item' className={cx('pad5', 'noWrp')}>
                                        {(isShowPrice && listPrice) || ''}
                                    </p>
                                    {this.enabledEURConversion && isShowPrice && (
                                        <p>{convertBGNToEUR(productPrice, false)}</p>
                                    )}
                                </div>
                            </div>
                            <div>
                                {quantity === maxQty && !isReadOnlyMode && (
                                    <div className={cx('maxQtyWarn')}>
                                        <Icon
                                            iconType='svg'
                                            width='17px'
                                            height='15px'
                                            viewBox='0 0 17 17'
                                            name='warning'
                                        />
                                        {commonLocalization[PLP_L10N.STOCK_ALLOCATION_LIMIT_MESSAGE]}
                                    </div>
                                )}
                                <AddToCart
                                    isFromProductCards
                                    isReadOnlyMode={isReadOnlyMode}
                                    ignoreSavedCart={isReturnEmpties || isMarketingMaterials}
                                    baseUnit={baseUnit}
                                    isUpdateSap={isUpdateSap}
                                    isQtyError={isQtyError}
                                    skuId={skuId || sku.id}
                                    automationId='at-add-to-card-button'
                                    autoHeight
                                    customAddtoCartStyle={cx(
                                        'btn-addtocart',
                                        isShowFilter ? 'hidenAddBtn' : 'filterAddBtn'
                                    )}
                                    iconName='addCart-red'
                                    buttonValue={
                                        isFrom === 'HOME' && deviceType.isDesktop
                                            ? localization[HOME_L10N.ADD_BTN]
                                            : commonLocalization[GENERIC_L10N.ADD_TO_CART]
                                    }
                                    productInfo={[
                                        {
                                            code,
                                            quantityType: stateUnits.id,
                                            quantity,
                                            conversionFactors
                                        }
                                    ]}
                                    analyticsProductInfo={[
                                        {
                                            name,
                                            // id: code.substring(22),
                                            id: skuId || sku.id,
                                            uom: stateUnits.id,
                                            quantity,
                                            list: gtmList,
                                            price: price?.formattedValue || listPrice, // productObj.price
                                            brand, // productObj.brand
                                            category //productObj.cat
                                        }
                                    ]}
                                    maxValue={maxQty}
                                    stockFlag={stockFlag}
                                    stockNotified={stockNotified}
                                    openStockNotificationModal={() =>
                                        this.props.openStockNotificationModal
                                            ? this.props.openStockNotificationModal(code)
                                            : this.props.actions.openSubscriptionPopup(code)
                                    }
                                    addToCartMessage={addToCartMessage}
                                    addToCartNotifyButton={this.props.addToCartNotifyButton}
                                    addToCartNotifiedButton={this.props.addToCartNotifiedButton}
                                    blockAddToCartEnabled={blockAddToCartEnabled}
                                    bdaaRecommendedCheckboxInfo={bdaaRecommendedCheckboxInfo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    };

    renderProductCardList = (stateUnits, gtmList) => {
        const { quantity, isQtyError } = this.state;
        const {
            forwardRef,
            sku,
            skuId,
            depositProduct,
            depositProductLogoUrl,
            recommendedQuantity,
            recommendedReason
        } = this.props;
        return (
            <div ref={forwardRef} key={skuId || sku.id} onClick={this.handleProductClickEvent}>
                <ProductCardListView
                    parentProps={this.props}
                    quantity={quantity}
                    isQtyError={isQtyError}
                    handleProductClickEvent={this.handleProductClickEvent}
                    goToProductPage={this.goToProductPage}
                    productUrl={this.productUrl}
                    updateUnits={this.updateUnits}
                    renderFavTermIcons={this.renderFavTermIcons}
                    stateUnits={stateUnits}
                    gtmList={gtmList}
                    depositProduct={depositProduct}
                    depositProductLogoUrl={depositProductLogoUrl}
                    recommendedQuantity={recommendedQuantity}
                    recommendedReason={recommendedReason}
                />
            </div>
        );
    };

    render() {
        const { units, isGrid, isFrom, plpType } = this.props;

        let { units: stateUnits } = this.state;
        if (Object.keys(stateUnits).length === 0) {
            stateUnits = units[0] && units[0].id ? units[0] : {};
        }

        let gtmList =
            {
                HOME: DATA_LAYER_LISTS.HOMEPAGE_FAVORITE_LIST,
                PLP: DATA_LAYER_LISTS.PRODUCT_LIST
            }[isFrom] || '';

        if (gtmList === DATA_LAYER_LISTS.PRODUCT_LIST) {
            if (plpType === 'search') {
                gtmList = DATA_LAYER_LISTS.SEARCH_LIST;
            } else if (store.getState().products.queryResult === '&isFavorite=true') {
                gtmList = DATA_LAYER_LISTS.FAVORITE_LIST;
            }
        }

        return isGrid
            ? this.renderProductCardGrid(stateUnits, gtmList)
            : this.renderProductCardList(stateUnits, gtmList);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            removeFavouriteEvent,
            addToFavouriteEvent,
            updateTempMiniCart,
            updateBdaaProductFavouriteProp,
            openSubscriptionPopup
        },
        dispatch
    )
});

const mapStateToProps = state => {
    return {
        assetBaseUrl: _get(state, 'context.environment.assetBaseUrl'),
        productList: _get(state, 'products'),
        miniCartItems: _get(state, 'cartDetails.miniCartItems', []),
        plpMiniCartItems: _get(state, 'cartDetails.plpMiniCartItems', []),
        localization: _get(state, 'pageContent.localization'),
        deviceType: _get(state, 'context.deviceType'),
        totalCartCount: _get(state, 'cartDetails.miniCart.totalCartCount'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

ProductCards.propTypes = {
    name: PropTypes.string,
    skuId: PropTypes.string,
    listPrice: PropTypes.string,
    pricePerPiece: PropTypes.string,
    units: PropTypes.arrayOf(PropTypes.object),
    // minQty: PropTypes.number,
    promotion: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isFav: PropTypes.bool,
    isReadOnlyMode: PropTypes.bool,
    isPromoProduct: PropTypes.bool,
    isPromo: PropTypes.bool,
    imageUrl: PropTypes.string,
    isGrid: PropTypes.bool,
    selectedView: PropTypes.string,
    isShowFilter: PropTypes.bool,
    isShowPrice: PropTypes.bool,
    isReturnEmpties: PropTypes.bool,
    isMarketingMaterials: PropTypes.bool,
    isFrom: PropTypes.string,
    selectedMobileView: PropTypes.string,
    url: PropTypes.string,
    code: PropTypes.string,
    addToCartMessage: PropTypes.string,
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    depositProduct: PropTypes.bool,
    depositProductLogoUrl: PropTypes.string,
    recommendedQuantity: PropTypes.number,
    recommendedReason: PropTypes.string
};

ProductCards.defaultProps = {
    selectedView: '',
    name: '',
    skuId: '',
    listPrice: '',
    pricePerPiece: '',
    units: [],
    // minQty: 1,
    isFav: false,
    isReadOnlyMode: false,
    isPromoProduct: false,
    isPromo: false,
    promotion: {},
    imageUrl: '',
    isGrid: true,
    isShowFilter: false,
    isShowPrice: false,
    isReturnEmpties: false,
    isMarketingMaterials: false,
    isFrom: 'PLP',
    selectedMobileView: '',
    url: '',
    code: '',
    addToCartMessage: '',
    commonLocalization: {},
    recommendedQuantity: null,
    recommendedReason: ''
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCards);
