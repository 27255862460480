import { call, fork, put, takeLatest } from 'redux-saga/effects';
import _get from 'lodash/get';
import FactorySaga from './FactorySaga';
import {
    getSelectedOutlet,
    getOutletList,
    changeOutlet,
    loadOutletPrices,
    getDefaultDeliverDate,
    getStockAllocation,
    getPricingConditions
} from '../interfaces/Outlet/OutletApi';

import {
    GET_SELECTED_OUTLET_REQUEST,
    GET_SELECTED_OUTLET_SUCCESS,
    GET_OUTLET_LIST_REQUEST,
    GET_OUTLET_LIST_SUCCESS,
    SUBMIT_SELECTED_OUTLET,
    OUTLET_ERROR,
    GET_STOCK_ALLOCATION,
    GET_STOCK_ALLOCATION_SUCCESS,
    GET_DELIVERY_INFO
} from '../actionTypes/PageContentActionTypes';
import { UPDATE_USER_TYPE } from '../actionTypes/LoginActionTypes';
import {
    setActiveCartId,
    setReturnEmpties,
    setMarketingMaterials,
    setUserType,
    setPickUpAllowed,
    setCustomerGroup,
    setZADOrderType,
    setOrderSplitIndirect,
    setBdaaActive,
    setMainParterId,
    setMainPartnerCountry,
    setExcelOrderUpload,
    setLoadedPrices,
    setLoyaltyCartMessage,
    setSelfiRedCartMessage,
    setPromoDisclaimerMessage,
    setUserChatActivated,
    setDefaultDeliveryDate,
    setMiniCartWorkingWay,
    mainPartnerId,
    setStockAllowed,
    setRemovingOosProductsEnabled,
    setLoyaltyEnabled,
    setChatBotEnabled,
    setDeliveryDatePreference,
    getDeliveryDatePreference,
    setInitialDeliveryDate,
    getInitialDeliveryDate,
    setEnabledEURConversion
} from '../common/UserManager/UserManager';
import { PLP_TYPES, URL_PATHS, LOGIN_INFO } from '../common/Constants';
import Storage from '../common/Storage/Storage';
import { CLEAR_MINI_CART } from '../actionTypes/CartActionTypes';
import { getBdaaSuggestion, setBdaaIsActive } from '../actions/ProductListAction';
import { getAllLoyaltyNotifications } from '../actions/LoyaltyAction';
import { setPickUpIsAllowed } from '../actions/LoginAction';
import { changeOrderType, getMultiCart } from '../actions/CartAction';
import { restoreCartSaga } from './CartSaga';
import { identifyFullStory } from '../common/Utils/Utils';

export function* getStockAllocationSaga(action) {
    try {
        const params = {
            ...action,
            ...{
                payload: {
                    params: {
                        pageId: action.payload.pageId,
                        b2bUnitId: mainPartnerId().toString()
                    }
                }
            }
        };
        const stockAllocationResult = yield call(FactorySaga, getStockAllocation, params);
        if (stockAllocationResult.isSuccess) {
            yield put({ type: GET_STOCK_ALLOCATION_SUCCESS, data: _get(stockAllocationResult, 'response.data') });
        }
    } catch (err) {
        yield put({ type: OUTLET_ERROR });
    }
}

export function* getSelectedOutletSaga(action) {
    try {
        action.isSkipLoader = false;
        const outletResult = yield call(FactorySaga, getSelectedOutlet, action);

        if (outletResult.isSuccess) {
            yield put({ type: GET_SELECTED_OUTLET_SUCCESS, data: _get(outletResult, 'response.data') });
            const customerTypeInfo = _get(outletResult, 'response.data.customerTypeInfo');
            const data = _get(outletResult, 'response.data');
            const {
                indirectCustomer,
                pickUpAllowed,
                assignedCustGroupCode,
                isZADOrderType,
                orderSplitIndirect,
                bdaaActive,
                removingOosProductsEnabled,
                chatBotEnabled,
                excelOrderUpload,
                miniCartWorkingWay,
                miniCartMessageShown,
                showMiniCartPreferences,
                stockAllowed,
                showLoyaltyPopup,
                loyaltyEnabled,
                enabledEURConversion,
            } = customerTypeInfo || {};
            const {
                mainPartnerId,
                mainPartnerAddress: { country }
            } = data;

            // Once you have the outlet partnerId, you can call the pricing conditions API with it in its own thread.
            if (mainPartnerId) {
                action.payload.params = {
                    ...action.payload.params,
                    ...{
                        partnerId: mainPartnerId
                    }
                }
                yield fork(FactorySaga, getPricingConditions, action);
            } 
    
            // Setting Customer Type Info in Storage.
            setUserType(indirectCustomer);
            setPickUpAllowed(pickUpAllowed);
            setCustomerGroup(assignedCustGroupCode);
            setZADOrderType(isZADOrderType);
            setOrderSplitIndirect(orderSplitIndirect);
            setBdaaActive(bdaaActive);
            setChatBotEnabled(chatBotEnabled);
            setRemovingOosProductsEnabled(removingOosProductsEnabled);
            setExcelOrderUpload(excelOrderUpload);
            setMainParterId(mainPartnerId);
            setMainPartnerCountry(country);
            setMiniCartWorkingWay(miniCartWorkingWay);
            setStockAllowed(stockAllowed);
            //Reset messages
            setLoyaltyCartMessage();
            setSelfiRedCartMessage();
            setPromoDisclaimerMessage();
            setLoyaltyEnabled(loyaltyEnabled);
            setEnabledEURConversion(enabledEURConversion);

            // Set FullStory User Id & Country Code
            identifyFullStory();

            yield put(setBdaaIsActive(bdaaActive));
            yield put(getAllLoyaltyNotifications());
            yield put(setPickUpIsAllowed(pickUpAllowed));

            if (bdaaActive) {
                yield put(getBdaaSuggestion({ data: { countryCode: country, customerId: mainPartnerId } }));
            }
            yield call(getStockAllocationSaga, action);

            if (window.location.pathname !== '/plp/zpop' && window.location.pathname !== '/plp/return') {
                yield* restoreCartSaga({ pageId: action.payload.pageId, isIgnoreFreeItemIteration: true });
            } else {
                if (window.location.pathname === '/plp/zpop') {
                    yield put(changeOrderType({ type: PLP_TYPES.ZPOP }));
                }

                if (window.location.pathname === '/plp/return') {
                    yield put(changeOrderType({ type: PLP_TYPES.RETURN }));
                }
            }

            yield call(getMultiCart);

            if (customerTypeInfo) {
                yield put({
                    type: UPDATE_USER_TYPE,
                    data: {
                        isIndirectUser: indirectCustomer,
                        pickUpAllowed,
                        isZADOrderType,
                        orderSplitIndirect,
                        bdaaActive,
                        chatBotEnabled,
                        excelOrderUpload,
                        showMiniCartPreferences,
                        miniCartMessageShown,
                        showLoyaltyPopup,
                        loyaltyEnabled
                    }
                });
            }
        }
    } catch (err) {
        yield put({ type: OUTLET_ERROR });
    }
}

export function* getOutletListSaga(action) {
    try {
        action.isSkipLoader = true;
        const result = yield call(FactorySaga, getOutletList, action);

        if (result.isSuccess) {
            yield put({
                type: GET_OUTLET_LIST_SUCCESS,
                data: _get(result, 'response.data')
            });
        }
    } catch (err) {
        yield put({ type: OUTLET_ERROR });
    }
}

export function* loadOutletPricesSaga(action) {
    try {
        action.isSkipLoader = true;
        const result = yield call(FactorySaga, loadOutletPrices, action);

        if (result.isSuccess) {
            setLoadedPrices(true);
        }
    } catch (err) {
        yield put({ type: OUTLET_ERROR });
    }
}

export function* submitOutletSaga(action) {
    try {
        const result = yield call(FactorySaga, changeOutlet, action);

        if (result.isSuccess) {
            // Remove preserved Cart Id.
            setActiveCartId();
            setReturnEmpties();
            setMarketingMaterials();
            setUserType();
            setPickUpAllowed();
            setCustomerGroup();
            setZADOrderType();
            setBdaaActive();
            setMainParterId();
            setMainPartnerCountry();
            setLoadedPrices();
            setMiniCartWorkingWay();
            // Clear the PLP URL Session.
            Storage.removeData(URL_PATHS.PLP_REFERRER_URL);

            // Clear userinfo Session.
            Storage.removeData(LOGIN_INFO.USER_INFO);

            // Clear delivery date preference
            Storage.removeData(LOGIN_INFO.DELIVERY_DATE_PREFERENCE);

            // Clear initial delivery date
            Storage.removeData(LOGIN_INFO.INITIAL_DELIVERY_DATE);

            // After change outlet redirect to home page. Hard Refresh to wipe out store data.

            //clean store and browser storage
            yield put({ type: CLEAR_MINI_CART });

            setUserChatActivated(undefined);
            window.location.href = URL_PATHS.HOME;
        }
    } catch (err) {
        yield put({ type: OUTLET_ERROR });
    }
}

export function* getDeliveryInfo(action) {
    try {
        action.isSkipLoader = true;
        const result = yield call(FactorySaga, getDefaultDeliverDate, action);
        const initialExistingDeliveryDate = getInitialDeliveryDate();
        if (result.isSuccess) {
            const dateData = _get(result, 'response.data');
            if (dateData?.deliveryDate && !initialExistingDeliveryDate) {
                setInitialDeliveryDate(dateData.deliveryDate);
            }
            setDefaultDeliveryDate(result.response.data);
        } else {
            yield put({ type: OUTLET_ERROR });
        }
    } catch (err) {
        yield put({ type: OUTLET_ERROR });
    }
}

export default function* watchOutletSagaRequest() {
    yield takeLatest(GET_SELECTED_OUTLET_REQUEST, getSelectedOutletSaga);
    yield takeLatest(GET_OUTLET_LIST_REQUEST, getOutletListSaga);
    yield takeLatest(SUBMIT_SELECTED_OUTLET, submitOutletSaga);
    yield takeLatest(GET_DELIVERY_INFO, getDeliveryInfo);
    yield takeLatest(GET_STOCK_ALLOCATION, getStockAllocationSaga);
}
